<template>
  <div class="body">
    <div class="link">
      <span>关于我们</span> <span>|</span> <span>成功案例</span> <span>|</span>
      <span>知名客户</span> <span>|</span> <span>诚征代理</span> <span>|</span>
      <span>诚聘英才</span> <span>|</span> <span>广告服务</span> <span>|</span>
      <span>友情链接</span> <span>|</span> <span>帮助中心</span> <span>|</span>
      <span>网站地图</span> <span>|</span>
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.body {
  border-top: 1px solid black;
  min-height: 120px;
  .link {
    margin: 0 auto;
    margin-top: 32px;
    text-align: center;
    span {
      margin-right: 24px;
    }
  }
}
</style>