import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import indexVue from '../views/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index',
    name: 'home',
    component: indexVue
  },
  {
    path: '/bidding',
    name: 'bidding',
    component: () => import('../views/bidding.vue')
  },
  {
    path: '/win',
    name: 'win',
    component: () => import('../views/win.vue')
  },
  {
    path: '/foretell',
    name: 'foretell',
    component: () => import('../views/foretell.vue')
  },
  {
    path: '/other',
    name: 'other',
    component: () => import('../views/other.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
