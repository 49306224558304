<template>
  <div class="body">
    <div class="message">
      <div class="title">
        <h1>山东同信同德电力工程有限公司</h1>
      </div>
    </div>
    <div class="addressBook">
      <div class="title">企业简介</div>
      <div class="content">
        <p
          style="
            line-height: 36px;
            text-indent: 2rem;
            text-align: justify;
            padding: 8px 32px;
            box-sizing: border-box;
          "
        >
          山东同信同德电力工程有限公司成立于2019年06月11日，注册地位于山东省济南市历城区唐冶西路868号山东设计创意产业园7号楼1-2306-27室，法定代表人为谢媛。经营范围包括许可项目：输电、供电、受电电力设施的安装、维修和试验；建设工程施工；电气安装服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）一般项目：园林绿化工程施工。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
        </p>
        <img
          src="../assets/img/ea6f1e18-380d-47e1-af74-2e29c048ad2f.png"
          alt=""
        />
      </div>
    </div>
    <div class="addressBook dynamic">
      <div class="title">最近活跃动态</div>
      <div class="content">
        <table>
          <thead>
            <tr>
              <th>标题</th>
              <th>中标单位</th>
              <th>发布时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>曲麻莱县扎什加羊高效养殖园区建设项目中标(成交)结果公告</td>
              <td>山东君玺农牧科技有限公司</td>
              <td>2024-05-22</td>
            </tr>
            <tr>
              <td>
                山东省潍坊市青州市机关事务服务中心农业大厦办公场所物业管理服务采购项目成交公告
              </td>
              <td>山东春辰物业有限公司</td>
              <td>2024-04-12</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">
                <span
                  ><router-link to="/win"
                    >点击查看更多&nbsp;>>></router-link
                  ></span
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
* {
  text-decoration: none;
}
.body {
  width: 70%;
  margin: 30px auto;
  .message {
    .title {
      height: 85px;
      padding: 15px 30px;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      background: linear-gradient(180deg, #ecf2fd, #ffffff 41%);
      border: 2px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: default;
      vertical-align: middle;
      text-align: left;
      h1 {
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        color: #333 !important;
        font-family: "宋体";
      }
    }
    .messageBox {
      display: flex;
      justify-content: space-between;
      .messageNumber {
        height: 60px;
        width: 200px;
        position: relative;
        font-size: 14px;
        .content {
          color: #4680ff;
          justify-content: center;
          align-items: flex-end;
          display: flex;
          height: 28px;
          margin-bottom: 8px;
          .bigWord {
            font-size: 28px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      .messageNumber::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 57px;
        background: linear-gradient(
          180deg,
          #3d67ad,
          rgba(61, 103, 173, 0) 0%,
          #3d67ad 50%,
          rgba(61, 103, 173, 0) 100%
        );
      }
      .iconBox {
        .content {
          color: #c6a36d;
          font-size: 18px;
          img {
            margin: 0 6px;
          }
        }
      }
      .iconBox::after {
        background: #ffffff;
      }
    }
  }
  .addressBook {
    margin-top: 24px;
    .title {
      position: relative;
      padding-left: 15px;
      padding-bottom: 30px;
      font-size: 18px;
      font-weight: bolder;
      text-align: left;
      font-size: 20px;
      color: #333 !important;
      font-family: "宋体";
    }
    .title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 20px;
      background-color: #1557c7;
      border-radius: 20px;
    }
    .content {
      display: flex;
      position: relative;
      img {
        z-index: 999;
      }
    }
  }
  .dynamic {
    .content {
      tfoot {
        td {
          color: #1557c7;
        }
      }
    }
  }
  // 表格统一样式
  table {
    width: 1210px;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #2531dda1;
    text-align: center;
    thead {
      background-color: #2531dda1;
    }
    th {
      line-height: 40px;
      border: 1px solid #2531dda1;
    }
    td {
      font-size: 14px;
      border: 1px solid #2531dda1;
      line-height: 40px;
    }
    tbody {
      .title {
        line-height: 40px;
        width: 840px;
        text-align: left;
        padding-left: 20px;
        box-sizing: border-box;
      }
      .address {
        line-height: 40px;
        width: 140px;
      }
      .time {
        line-height: 40px;
        width: 160px;
      }
    }
    tfoot {
      td:hover {
        cursor: pointer;
        span {
          color: red;
        }
      }
    }
  }
}
</style>