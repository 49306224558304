<template lang="">
    <div class="body">
        <p>山东同信同德电力工程有限公司</p>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.body {
  width: 100%;
  height: 140px;
  background-image: url("../assets/img/comp_banner.jpg");
  background-size: 100%;
  p {
    position: absolute;
    top: 30px;
    left: 21%;
    font-size: 24px;
    color: #2531dd;
  }
}
</style>